import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap'; // 不使用 Container 元件，實現全屏
import Home from './Home';
import MapComponent from './IMC-map';
import Coordinate from './update_coordinate';
import EmployeeTable from './Employee';
import EditEmployee from './EmployeeEdit';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('token') !== null; // 判斷是否有 JWT Token
  });

  // 登入邏輯
  const handleLogin = async () => {
    const mobile = prompt('請輸入手機號碼作為密碼:');

    try {
      const response = await fetch('http://localhost:5000/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ mobile })
      });

      const data = await response.json();

      if (response.ok) {
        // 保存 JWT Token 到 localStorage
        localStorage.setItem('token', data.token);
        setIsAuthenticated(true);
        alert('登入成功');
      } else {
        alert(data.message || '登入失敗');
      }
    } catch (error) {
      console.error('登入錯誤:', error);
      alert('登入失敗，請重試');
    }
  };

  // 登出邏輯
  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };
  return (
    <Router>
      <div>
        {/* 將 Navbar 設置為暗黑模式並全寬 */}
        <Navbar bg="dark" variant="dark" expand="lg" className="w-100">
          <Navbar.Brand as={Link} to="/">IMC3.0</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">活動報名</Nav.Link>
              <Nav.Link as={Link} to="/IMC-map">社友地圖</Nav.Link>
              <Nav.Link as={Link} to="/update_coordinate">更新坐標</Nav.Link>
              <li className="nav-item">
                {/* 受限功能，點擊時要求輸入密碼 */}
                <Link className="nav-link text-white fw-bold" to="#" onClick={handleLogin}>
                  {isAuthenticated ? '切換帳號' : '登入'}
                </Link>
              </li>
              {/* 如果通過驗證才顯示隱藏的功能 */}
              {isAuthenticated && (
                <>
                  <Nav.Link as={Link} to="/Employee">社友資訊</Nav.Link>
                  {/* 添加登出按鈕 */}
                  <Nav.Link as={Link} to="/" onClick={handleLogout}>登出</Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/* 全屏內容區域 */}
        <div style={{ width: '100vw', padding: '0', margin: '0' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/IMC-map" element={<MapComponent />} />
            <Route path="/update_coordinate" element={<Coordinate />} />
            {/* 保護路由，僅當 isAuthenticated 為 true 時可訪問 */}
            <Route path="/Employee" element={isAuthenticated ? <EmployeeTable /> : <Home />} />
            <Route path="/EmployeeEdit/:id" element={isAuthenticated ? <EditEmployee /> : <Home />} />

          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

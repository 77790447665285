import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { MarkerClusterer } from '@react-google-maps/api';
import axios from 'axios';

const MapComponent = () => {
  const [currentLocation, setCurrentLocation] = useState({ lat: 23.7147, lng: 120.5390 });
  const [selected, setSelected] = useState(null);
  const [locations, setLocations] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }

    axios.get('https://app.li.com.tw/api/locations')
      .then(response => {
        setLocations(response.data);
        setFilteredLocations(response.data); // Initially show all locations
      })
      .catch(error => {
        console.error("Error fetching location data:", error);
      });
  }, []);

  useEffect(() => {
    const socket = new WebSocket('wss://app.li.com.tw/ws');
  
    socket.onopen = () => {
      console.log('WebSocket connection opened');
    };
  
    socket.onmessage = (event) => {
      console.log('Received:', event.data);
      // 處理收到的消息
    };
  
    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
  
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    return () => {
      socket.close();
    };
  }, []);
  

  useEffect(() => {
    if (searchName.trim() !== '') {
      const filtered = locations.filter(location => {
        // Combine the three properties into a single string
        const combinedText = `${location.s_ss_name}${location.g_name}${location.e_name}`.toLowerCase();
        // Check if the search term is included in the combined text
        return combinedText.includes(searchName.toLowerCase());
      });
      setFilteredLocations(filtered);
      // Update suggestions based on the combined text
      setSuggestions(filtered.map(location => `${location.s_ss_name}${location.g_name}${location.e_name}`));
    } else {
      setFilteredLocations(locations);
      setSuggestions([]);
    }
  }, [searchName, locations]);
  
  const handleSuggestionClick = (suggestion) => {
    setSearchName(suggestion);
    setSuggestions([]);
  };
  

  const mapStyles = {
    height: "100vh",
    width: "100%"
  };

  return (
    <LoadScript googleMapsApiKey='AIzaSyDgVylSVIknzV17Z48u0gc7grLdHCIFdXc'>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={12}
        center={currentLocation}
      >
        <MarkerClusterer>
          {(clusterer) =>
            filteredLocations.map((location) => (
              <Marker
                key={location.e_id}
                position={{ lat: location.m_lat, lng: location.m_lng }}
                clusterer={clusterer}
                onClick={() => setSelected(location)}
              />
            ))
          }
        </MarkerClusterer>
        {selected && (
          <InfoWindow
            position={{ lat: selected.m_lat, lng: selected.m_lng }}
            onCloseClick={() => setSelected(null)}
          >
            <div style={{ width: '250px', padding: '10px', textAlign: 'center' }}>
              {selected.e_photo && (
                <img 
                  src={`https://oneimc.com/admin/upload/employees/${selected.e_photo}`} 
                  alt={selected.e_name} 
                  style={{ width: '100px', height: '120px', borderRadius: '8px', marginBottom: '10px' }} 
                />
              )}
              <h2>{selected.s_ss_name}</h2>
              <h2>{selected.g_name}</h2>
              <h1>{selected.e_name}</h1>
              <h2>
              <a 
              href={`https://www.google.com.tw/maps/place/${selected.m_lat},${selected.m_lng}`} 
              target="_blank" 
              rel="noopener noreferrer"
              >導航
              </a>
              </h2>
              <h2><a href={`tel:${selected.e_mobile}`}>{selected.e_mobile}</a></h2>
              {selected.e_comp_web ? (
  <h2>
    <a 
      href={selected.e_comp_web.startsWith('http://') || selected.e_comp_web.startsWith('https://') ? selected.e_comp_web : `http://${selected.e_comp_web}`}
      target="_blank" 
      rel="noopener noreferrer"
    >
      {selected.e_comp_name}
    </a>
  </h2>
) : (
  <h2>{selected.e_comp_name}</h2>
)}
              <h2>{selected.e_comp_position}</h2>
            </div>
          </InfoWindow>
        )}
        <div style={{ position: 'absolute', top: 0, right: 0, transform: 'scale(1.5)', transformOrigin: 'top right' }}>
          <button onClick={() => window.location.reload()} style={{ padding: '0.5rem' }}>Reload</button>
        </div>
        <div style={{ position: 'absolute', top: 10, left: 10 }}>
          <input
            type="text"
            placeholder="Enter name"
            value={searchName}
            onChange={e => setSearchName(e.target.value)}
            style={{ fontSize: '1rem', padding: '0.5rem' }}
          />
          {suggestions.length > 0 && (
            <ul style={{
              listStyleType: 'none',
              padding: 0,
              margin: 0,
              backgroundColor: 'white',
              border: '1px solid #ccc',
              position: 'absolute',
              zIndex: 1000,
              maxHeight: '150px',
              overflowY: 'auto',
              width: 'calc(100% - 1rem)'
            }}>
              {suggestions.map((suggestion, index) => (
                <li 
                  key={index} 
                  onClick={() => handleSuggestionClick(suggestion)}
                  style={{ padding: '0.5rem', cursor: 'pointer', borderBottom: '1px solid #ccc' }}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
      </GoogleMap>
    </LoadScript>
  );
}

export default MapComponent;

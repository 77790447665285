import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap'; // 引入 React Bootstrap 元件
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Coordinate = () => {
  const [startId, setStartId] = useState('');
  const [endId, setEndId] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://app.li.com.tw/api/update-coordinates', {
        start_id: startId,
        end_id: endId
      });
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error updating coordinates:', error);
      setMessage('Error updating coordinates');
    }
  };

  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#343a40', color: '#fff', padding: '20px' }}>
      <h2 className="text-center mb-4">Update Employee Coordinates</h2>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
        <Form.Group controlId="startId" className="mb-3">
          <Form.Label>Start ID:</Form.Label>
          <Form.Control
            type="number"
            value={startId}
            onChange={(e) => setStartId(e.target.value)}
            placeholder="Enter Start ID"
            className="bg-dark text-white"
            style={{ width: '100%' }}
          />
        </Form.Group>
        <Form.Group controlId="endId" className="mb-3">
          <Form.Label>End ID:</Form.Label>
          <Form.Control
            type="number"
            value={endId}
            onChange={(e) => setEndId(e.target.value)}
            placeholder="Enter End ID"
            className="bg-dark text-white"
            style={{ width: '100%' }}
          />
        </Form.Group>
        <Button variant="primary" type="submit" style={{ width: '100%' }}>
          Update Coordinates
        </Button>
      </Form>
      {message && (
        <Alert variant={message.includes('Error') ? 'danger' : 'success'} className="mt-4" style={{ width: '100%' }}>
          {message}
        </Alert>
      )}
    </div>
  );
};

export default Coordinate;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Row, Col } from 'react-bootstrap';

const EditEmployee = () => {
  const { id } = useParams();
  const [employee, setEmployee] = useState({
    e_group: null,
    e_usrname: null,
    e_usrpwd: null,
    e_idcard: null,
    e_cardNum: null,
    e_idcard2: null,
    e_cardNum2: null,
    e_ssid: null,
    e_position: null,
    e_name: null,
    e_no: null,
    e_sex: null,
    e_birthday: null,
    e_marry: null,
    e_UID: null,
    e_foodType: null,
    e_size: null,
    e_tel: null,
    e_tel1: null,
    e_fax: null,
    e_mobile: null,
    e_zip: null,
    e_address: null,
    e_zip1: null,
    e_address1: null,
    e_addr_sel: null,
    e_set: null,
    e_set_position: null,
    e_slogan: null,
    e_comp_name: null,
    e_comp_position: null,
    e_comp_web: null,
    e_comp_sort: null,
    e_comp_open: null,
    e_comp_coupon: null,
    e_sell_info: null,
    e_email: null,
    e_line: null,
    e_fb: null,
    e_photo: null,
    e_remark: null,
    e_join_date: null,
    e_quit_date: null,
    e_payment_date: null,
    e_relation_uid: null,
    e_appellation: null,
    e_recom_uid: null,
    e_edit_uid: null,
    e_edit_day: null,
    e_login_time: null,
    sql_title1: null,
    sql_title2: null,
    sql_title3: null,
    sql_title4: null,
    sql_title5: null,
    sql_title6: null,
    sql_title7: null,
    sql_title8: null,
    sql_title9: null,
    sql_title10: null,
    e_seq: null,
    e_status: null,
    m_lat: null,
    m_lng: null,
    m_type: null,
    e_modified_dt: null,
    e_line_member_fk_id: null
  });


  useEffect(() => {
    axios.get(`/api/employees/${id}`)
      .then(response => {
        const data = response.data;
  
        // 將 null 值轉換為空字符串
        const updatedEmployee = {};
        for (let key in data) {
          updatedEmployee[key] = data[key] === null ? '' : data[key];
        }
        setEmployee(updatedEmployee);
      })
      .catch(error => {
        console.error('Error fetching employee:', error);
      });
  }, [id]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // 將空字符串轉換為 null，特別是針對 e_appellation
    setEmployee({
      ...employee,
      [name]: value === '' ? null : value
    });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`/api/employees/${id}`, employee)
      .then(response => {
        alert('員工資料已更新');
      })
      .catch(error => {
        if (error.response) {
          // 伺服器回應了狀態碼並且有錯誤訊息
          console.error('Error updating employee:', error.response.data);
          alert(`更新失敗：${error.response.data.error || '伺服器錯誤'}`);
        } else if (error.request) {
          // 請求已發送但伺服器沒有回應
          console.error('No response received:', error.request);
          alert('伺服器無回應，請稍後再試');
        } else {
          // 設定請求時出現錯誤
          console.error('Error setting up request:', error.message);
          alert('請求發送失敗，請檢查網路連接');
        }
      });
  };
  
  
  

  return (
    <Form onSubmit={handleSubmit} className="p-4 border rounded shadow-sm">
      <h4 className="mb-4">編輯資料</h4>
      
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>類別</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="number"
            name="e_group"
            value={employee.e_group}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>社別</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_ssid"
            value={employee.e_ssid}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>社職</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_position"
            value={employee.e_position}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>組別</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_set"
            value={employee.e_set}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>組稱</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_set_position"
            value={employee.e_set_position}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>姓名</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_name"
            value={employee.e_name}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>手機</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_mobile"
            value={employee.e_mobile}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>區號</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_zip"
            value={employee.e_zip}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>地址</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_address"
            value={employee.e_address}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>地址1</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_address1"
            value={employee.e_address1}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>公司名稱</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_comp_name"
            value={employee.e_comp_name}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>公司職位</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_comp_position"
            value={employee.e_comp_position}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>公司網站</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_comp_web"
            value={employee.e_comp_web}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>電子郵件</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_email"
            value={employee.e_email}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>公司電話</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_tel"
            value={employee.e_tel}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>公司傳真</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_fax"
            value={employee.e_fax}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>年度標語</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_slogan"
            value={employee.e_slogan}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>生日</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_birthday"
            value={employee.e_birthday}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>性別</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_sex"
            value={employee.e_sex}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>婚姻狀況</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_marry"
            value={employee.e_marry}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>飲食</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_foodType"
            value={employee.e_foodType}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>衣服</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_size"
            value={employee.e_size}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>差點</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="sql_title6"
            value={employee.sql_title6}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>相片</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_photo"
            value={employee.e_photo}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>備註</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_remark"
            value={employee.e_remark}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>入社日</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_join_date"
            value={employee.e_join_date}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>登入日</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_login_time"
            value={employee.e_login_time}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>繳費日</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_payment_date"
            value={employee.e_payment_date}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>關係社友</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="number"
            name="e_relation_uid"
            value={employee.e_relation_uid === null ? '' : employee.e_relation_uid}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>關係稱謂</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="number"
            name="e_appellation"
            value={employee.e_appellation}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>出生地</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="sql_title1"
            value={employee.sql_title1}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>學歷</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="sql_title2"
            value={employee.sql_title2}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>sql_title3</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="sql_title3"
            value={employee.sql_title3}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>關鍵字</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="sql_title4"
            value={employee.sql_title4}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>歷年職務</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="sql_title5"
            value={employee.sql_title5}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>sql_title7</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="sql_title7"
            value={employee.sql_title7}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>sql_title8</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="sql_title8"
            value={employee.sql_title8}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>sql_title9</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="sql_title9"
            value={employee.sql_title9}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>sql_title10</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="sql_title10"
            value={employee.sql_title10}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>排序</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_seq"
            value={employee.e_seq}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>不顯示</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_status"
            value={employee.e_status}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>m_lat</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="m_lat"
            value={employee.m_lat}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>m_lng</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="m_lng"
            value={employee.m_lng}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>m_type</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="m_type"
            value={employee.m_type}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>修改日期</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_modified_dt"
            value={employee.e_modified_dt}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>e_line_member_fk_id</Form.Label>
        </Col>
        <Col xs={9}>
          <Form.Control
            type="text"
            name="e_line_member_fk_id"
            value={employee.e_line_member_fk_id}
            onChange={handleInputChange}
          />
        </Col>
      </Row>


      <Button variant="primary" type="submit" className="w-100 mt-3">提交</Button>
    </Form>
  );
};

export default EditEmployee;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // 引入 Link
import 'bootstrap/dist/css/bootstrap.min.css';

const EmployeeTable = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);  // 用來控制分頁
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // 控制排序
  const [filter, setFilter] = useState('');  // 控制過濾條件
  const observer = useRef();

  // 加載資料的函數
  const fetchEmployees = async (page, sortKey = null, sortDirection = 'asc', filter = '') => {
    setLoading(true);
    try {
      const response = await axios.get('/api/employees', {
        params: {
          page,
          sortKey,
          sortDirection,
          filter,  // 添加過濾條件作為參數
        },
      });
      setEmployees((prevEmployees) => [...prevEmployees, ...response.data]); // 加載更多資料
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
    setLoading(false);
  };

  // 初始加載
  useEffect(() => {
    fetchEmployees(page);
  }, [page]);

  // 無限滾動
  const lastEmployeeRef = useRef();
  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);  // 滾動到底部後自動加載更多
      }
    });

    if (lastEmployeeRef.current) {
      observer.current.observe(lastEmployeeRef.current);
    }
  }, [loading]);

  // 排序函數
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setEmployees([]); // 清空當前的資料，準備載入新的排序資料
    setPage(1); // 重置分頁，從第一頁開始
    fetchEmployees(1, key, direction, filter); // 請求新的排序資料
  };

  // 過濾函數
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setEmployees([]);  // 清空當前資料
    setPage(1);  // 重置分頁
    fetchEmployees(1, sortConfig.key, sortConfig.direction, e.target.value); // 根據新的過濾條件請求數據
  };
  const handleDelete = (employeeId) => {
    if (window.confirm("確定要刪除這位員工嗎？")) {
      axios.delete(`/api/employees/${employeeId}`)
        .then(() => {
          // 刪除成功後，過濾掉已刪除的員工
          setEmployees(employees.filter(employee => employee.e_id !== employeeId));
        })
        .catch(error => {
          console.error('Error deleting employee:', error);
          alert('刪除失敗');
        });
    }
  };
  
  return (
    <div className="container-fluid mt-4">
      
      {/* 過濾器 */}
      <Form.Control
        type="text"
        placeholder="搜尋員工資料..."
        value={filter}
        onChange={handleFilterChange}
        className="mb-4"
      />

      {/* 結果表格 */}
      <Table striped bordered hover variant="dark" className="table-striped table-dark">
        <thead>
          <tr>
            <th onClick={() => handleSort('e_id')}>ID</th>
            <th onClick={() => handleSort('e_name')}>姓名</th>
            <th onClick={() => handleSort('e_mobile')}>手機</th>
            <th onClick={() => handleSort('store_name')}>社別</th>
            <th onClick={() => handleSort('position_title')}>社職</th>
            <th onClick={() => handleSort('e_address')}>地址</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee, index) => (
            <tr key={employee.e_id} ref={index === employees.length - 1 ? lastEmployeeRef : null}>
              <td>{employee.e_id}</td>
              <td>{employee.e_name}</td>
              {/* 電話號碼連結 */}
              <td><a href={`tel:${employee.e_mobile}`}>{employee.e_mobile}</a></td>
              <td>{employee.store_name || '無'}</td>
              <td>{employee.position_title || '無'}</td>
              {/* 地址連結到 Google Maps */}
              <td><a href={`https://www.google.com/maps/search/?api=1&query=${employee.e_address}`} target="_blank" rel="noopener noreferrer">{employee.e_address}</a></td>
              <td>
                {/* 編輯按鈕連結到編輯頁面 */}
                <Link to={`/EmployeeEdit/${employee.e_id}`}>
                  <Button variant="info" size="sm" className="me-2">編輯</Button>
                </Link>
                <Button variant="danger" size="sm" className="me-2" onClick={() => handleDelete(employee.e_id)}>刪除</Button>
              </td>
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan="7" className="text-center">加載中...</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default EmployeeTable;
